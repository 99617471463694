import clsx from 'clsx'
import { useRef, useState } from 'react'
import s from './styles.module.scss'

export interface KionSelectOption<T> {
  label: string
  icon?: string
  tooltip?: string
  value: T
}

export function KionSelect<T>(props: {
    placeholder: string
    label: string
    options: KionSelectOption<T>[]
    value: T
    onSelect: (val: any) => void
}) {
    const refDropdown = useRef<any>(null)
    const [selected, setSelected] = useState<T | null>(props.value || null)
    const [isOpen, setIsOpen] = useState(false)

    function openOptions() {
        setIsOpen(true)
        document.body.addEventListener('mouseup', outsideClickHandler)
    }

    function closeOptions() {
        setIsOpen(false)
        document.body.removeEventListener('mouseup', outsideClickHandler)
    }

    function outsideClickHandler(e: MouseEvent) {
        if (e.target !== refDropdown.current && !refDropdown.current?.contains(e.target as HTMLElement)) {
            closeOptions()
        }
    }

    function onSelect(opt: KionSelectOption<T>) {
        setSelected(opt.value)
        props.onSelect(opt.value)
        closeOptions()
    }

    function getSelected() {
        return props.options.find(x => JSON.stringify(x.value) == JSON.stringify(selected))
    }

    return (
        <div className={s.select__wrapper}>
            <div className={s.select__label}>
                { props.label }
            </div>
            <div 
                className={clsx(
                    isOpen && s.open, 
                    s.select__input
                )}
                onClick={openOptions}
            >
            {selected ? (
                <>
                    <img 
                        src={getSelected()?.icon}
                        className={s.icon}
                    />
                    <div>{ getSelected()?.label }</div>
                </>
                ) : (
                    <span>
                        { props.placeholder }
                    </span>
                )}
                <img 
                    className={s['dropdown-icon']}
                    src="/images/back.svg"
                />
            </div>

            {isOpen && (
                <div
                    ref={refDropdown}
                    className={s.select__options}
                >
                    {props.options.map((opt) => (
                        <div
                            key={opt.icon}
                            className={clsx(
                                s['select__options-item'], 
                                (selected ? JSON.stringify(selected) === JSON.stringify(opt.value) : false) && s.selected)
                            }
                            onClick={() => onSelect(opt)}
                        >
                            {opt.icon && (
                                <img 
                                    src={opt.icon}
                                    className={s.icon}
                                />
                            )}
                            <div className={s.label}>
                                { opt.label }<br/>
                                <span className={s.tooltip}>
                                    { opt.tooltip }
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}