import { IndexView } from './view';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SsoAuth } from './components/ssoauth/ssoauth';
import { ContextStore, useStoreCtx } from './store';
import { SubscriptionOffer } from './components/subscription/subscription';
import { KionSuccess } from './components/subscription/success/success';
import { KionError } from './components/subscription/error/error';

function App() {
  const store = useStoreCtx()

  return (
    <ContextStore.Provider value={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IndexView/>}/>
          <Route path="ssoauth" element={<SsoAuth/>}/>
          <Route path="subscriptions" element={<SubscriptionOffer/>}/>
          <Route path="success" element={<KionSuccess/>}/>
          <Route path="error" element={<KionError/>}/>
        </Routes>
      </BrowserRouter>
      <div id="bank-payment-widget" />
    </ContextStore.Provider>
  );
}

export default App;
