import { createContext, useContext } from "react";
import { HttpService } from "./appStore";

export function useStore(): HttpService {
  return new HttpService()
}

export const ContextStore = createContext({appStore: new HttpService()})

export function useStoreCtx() {
  return useContext(ContextStore)
}