import { useEffect } from "react";
import s from "./styles.module.scss";
import { observer } from "mobx-react-lite";
import { createPortal } from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useStoreCtx } from "../../store";
import { parseJWT, parseUriHash } from "../../util/HashParser";
import { DataLayer, forceRedirect } from "../../store/appStore";

export const SsoAuth = observer(() => {
  const httpService = useStoreCtx().appStore;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const auth = async () => {
      const gtmCommonData: DataLayer = {
        auth_method: "sms",
        auth_type: "websso",
      };

      const hash = location.hash;

      if (Object.keys(location.search).length === 0 && !hash) {
        navigate("/");
      }
      const { state, code, id_token } = parseUriHash(hash);
      httpService.jwtToken = id_token;
      const { phone, nonce, sub } = (httpService.webSSOJwt = parseJWT(id_token));
      // httpService.promoCode = state;
      httpService.phone = phone;

      try {
        console.log("try to auth");
        const { accessToken, firstLogin } = (await httpService.auth(nonce, code)).data;
        httpService.accessToken = accessToken;
        const { huaweiSubscriberId } = (await httpService.getUserId()).data;
        httpService.userId = sub;
        httpService.grclientid = huaweiSubscriberId;

        if (firstLogin) {
          httpService.pushEvent({
            event: "registration",
            event_name: "registration",
            ...gtmCommonData,
          });
        }

        httpService.pushEvent({
          event: "authorization_success",
          event_name: "authorization_success",
          request_id: sessionStorage.getItem("request_id"),
          ...gtmCommonData,
        });

        // Проверка подписки
        const statusPromotion = (await httpService.filterAppliedSubscriptions(httpService.selectedSubscription)).data
        if (statusPromotion.length !== 0) {
          window.sessionStorage.setItem('subscriptionExist', '1')
          httpService.subscriptionExist = true
          navigate("/");
          return
        }

        if (forceRedirect) navigate("/");

        navigate({pathname: "/subscriptions", search: new URLSearchParams(httpService.getAnalyticsQuery()).toString()});
      } catch (e) {
        console.log("err", e);
        navigate({pathname: '/error', search: new URLSearchParams(httpService.getAnalyticsQuery()).toString()})
      }
    };

    auth();
  }, []);

  return createPortal(
    <div className={s.overlay}>
      <div className={s.modal}>
        <div className={s.modal__content}>
          <div className={s.wrapper}>Авторизация...</div>
        </div>
      </div>
    </div>,
    document.body
  );
});
