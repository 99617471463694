import jwt_decode from 'jwt-decode'

export interface WebSsoJWT {
  aud: string
  auditTrackingId: string
  auth_time: number
  azp: string
  c_hash: string
  exp: number
  iat: number
  iss: string
  "mnp:operator": string
  "mnp:region": string
  nonce: string
  "org.forgerock.openidconnect.ops": string
  person_id: string
  phone: string
  picture: string
  "profile:type": string
  raw_data: unknown[]
  realm: string
  sub: string
  tokenName: string
  tokenType: string
}

interface WebSsoResp {
  code: string,
  id_token: string,
  scope: string,
  state: string
}

export function parseUriHash(hash: string): WebSsoResp | { [key: string]: string } {
  const arr = hash.replace('#', '').split('&')
  return arr.reduce((acc: { [key: string]: string }, el) => {
    const [key, value] = el.split('=')
    return { ...acc, [key]: decodeURI(value) }
  }, {})
}

export const tvhEnvironmentApi = 'prod.tvh.mts.ru'

export function parseJWT(token: string): WebSsoJWT | { [key: string]: string } {
  return jwt_decode(token)
}
