import { KionContent, SHELVES_ITEM_LINK } from "../../../util/interfaces";
import { KionButton } from "../../button/button"
import s from './styles.module.scss'

export function KionSuccess() {
  function moveTo(link: string) {
    window.location.assign(link)
  }

  const content = window.sessionStorage.getItem("content");
  const contentObj: KionContent = content !== null ? JSON.parse(content) : {};

  const getTypeContentForParam = (type: string) => {
	switch (type) {
		case SHELVES_ITEM_LINK.SERIES: { return 'serial' };
		case SHELVES_ITEM_LINK.MOVIE: { return 'movie' };
	};
  };

  return (
    <div style={{
      background: '#090B3F',
      height: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <div className={s.wrapper}>
        <h2 className={s.title}>
            <div className={s.icon}>
              <img src="/images/done-icon.svg"/>
            </div>
            Подписка успешно подключена!
        </h2>
        <div className={s.subtitle}>
			{content ? (
				<div className={s.label}>Теперь вы можете посмотреть «{contentObj.title}»</div>
			) : (
				<div className={s.label}>Теперь вы можете перейти на сайт кинотеатра или установить наше приложение</div>
			)}
        </div>

        <KionButton 
			onClick={() => moveTo(content 
				? `https://kion.ru/video/${getTypeContentForParam(contentObj.type)}/${contentObj.hid}`
				: 'https://kion.ru'
			)}
		>
            {content ? 'Перейти к просмотру' : 'Перейти на kion.ru'}
        </KionButton>

        {/* <div className={s['app-ref-row']}>
            <img src="/images/appstores//google-play.svg"
                onClick={() => moveTo('https://kion.onelink.me/26fZ/efbea3a3')}/>
            <img src="/images/appstores//app-store.svg"
                onClick={() => moveTo('https://kion.onelink.me/26fZ/efbea3a3')}/>
            <img src="/images/appstores//app-gallery.svg"
                onClick={() => moveTo('https://appgallery.huawei.com/#/app/C101183499')}/>
        </div> */}
      </div>
    </div>
  )
}
