import clsx from "clsx"
import { PropsWithChildren } from "react"
import s from './styles.module.scss'

export function KionButton(props: PropsWithChildren<{
    disabled?: boolean
    negative?: boolean
    onClick: () => void
}>) {
    return (
        <button 
            className={clsx(
                s['kion-button'], 
                props.disabled && s.disabled, 
                props.negative && s.negative
            )}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}