import { makeAutoObservable } from "mobx"
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { KION_PICTURE_TYPE, Subscription } from "../util/promo"
import { KionContent, KionPromoCodeResponse, KION_TARIFF_PERIOD, SHELVES_ITEM_LINK } from "../util/interfaces"
import { WebSsoJWT } from "../util/HashParser"
import { getClientID } from "../util/helpers"

export type DLEvent = 'screen_show' | 'registration' | 'authorization_success' | 'subscription_success' | 'authorization_start' | 'subscribe_error' | 'content_error'

export type DLEventProfile = 'mts_guest' | string

export interface DataLayer {
    event?: DLEvent | null
    event_name?: DLEvent | null
    userId?: string | null
    client_id?: string | null
    event_datetime?: string | null
    screen?: string | null
    auth_method?: string | null
    auth_type?: 'mtstv' | 'websso' | 'seamless' | null
    request_id?: string | null
    experiments?: string[] | null
    huawei_subscriber_id?: DLEventProfile | null
    profile_id?: DLEventProfile | null
    profile_age?: string | null
    referer?: string | null
    error_message?: string | null
    price?: number | null
    subscription_name?: string | null
    subscription_id?: string | null
    promocode?: string | null
    deeplink?: string | null
    platform?: string | null
    is_trial?: 0 | 1 | null
    transaction_id?: string | null
    payment_type?: "vps" | "inapp" | "account" | "mts_money" | null
}

declare const dataLayer: DataLayer[]
declare const dataLayerMTS: DataLayer[]

interface AuthResponse {
  userId: string
  accessToken: string
  refreshToken: string
  login: string
  password: string
  tokenType: string
  firstLogin: boolean
  expiresIn: number
}

export interface RelatedSubscriptionsTextRequest {
	productGid: string;
	promoCode: string;
	paymentType: PaymentType;
}

export interface PaymentType {
	source: PaymentTypeEnum;
	system: PaymentSystemEnum;
}

export const enum PaymentSystemEnum {
	FORIS_ONLINE = 'FORIS_ONLINE',
	MTS_MONEY = 'MTS_MONEY',
}

export const enum PaymentTypeEnum {
	ACCOUNT = 'ACCOUNT',
	CARD = 'CARD',
}

export let forceRedirect: boolean = false;

export const setForceRedirect = (fr: boolean) => {
  forceRedirect = fr;
}
export interface MtsBankWidgetResponse {
  isSuccess: boolean
  errorCode?: string
  message?: string
  eventName: 'successPayment' | 'failPayment' | 'validationError' | 'close'
}

export interface KionPostersResponse {
  title: string,
  items: KionPostersItemResponse[]
}

export interface KionPostersItemResponse {
  title: string,
  imageUrl: string,
  link: KionPostersItemResponseLink,
}

export interface KionPostersItemResponseLink {
  linkType: SHELVES_ITEM_LINK,
  seriesContentId: string,
  movieContentId: string,
}

interface Promo {
  promoCode: string | null
  productId: string
  discountPrice: number
}

export interface MtsBankWidgetConfig {
  sessionId: string,
  scenarioType: 'pay',
  tokenId: string,
  environment: 'PROD',
  resultHandler: (resp: MtsBankWidgetResponse) => void
}

export interface CancelingProductGids {
  blockingProductGids: any[];
  button: string;
  cancelingProductGids: any[];
  header: string;
  text: string;
}  

export class HttpService {
  constructor() {
    makeAutoObservable(this)
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768
    })
  }

  isMobile: boolean = window.innerWidth < 768

  _subscriptionExist: boolean = window.sessionStorage.getItem('subscriptionExist') === '1';
  set subscriptionExist(value: boolean) {
    this._subscriptionExist = value
  }
  get subscriptionExist() {
    return this._subscriptionExist
  }

  get selectedSubscription(): Subscription {
    return this._selectedSubscription;
  }

  set selectedSubscription(val: Subscription) {
	  this._selectedSubscription = val
  }

  public subscriptions: Subscription[] = [
	  {
		name: "KION+PREMIUM",
		description: "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
		ageRating: 12,
		discountPrice: 0,
		price: 24900,
		promotion: {
			timeToLiveValue: 1,
			period: 'месяц'
		},
		transcription: '',
		productId: 'kion_base_premium_p_04_01_01_02_bvod_per_month_mts_ott_sdhd4k_b2c_v2_07_2024',
		tariffPeriod: KION_TARIFF_PERIOD.MONTHLY,
		image: '/images/20240427142200453ld8.d8a2d75d.jpg',
		isBigPicture: KION_PICTURE_TYPE.BIG
	  },
	  {
		name: "KION+PREMIUM",
		description: "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
		ageRating: 12,
		discountPrice: 24900,
		price: 54900,
		promotion: {
			timeToLiveValue: 3,
			period: '3 месяца'
		},
		transcription: '',
		productId: 'kion_base_premium_p_04_01_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_07_2024',
		tariffPeriod: KION_TARIFF_PERIOD.MULTIMONTH3,
		image: '/images/20240427142200453ld8.d8a2d75d.jpg',
		isBigPicture: KION_PICTURE_TYPE.BIG
	  },
	  {
		name: "KION+PREMIUM",
		description: "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
		ageRating: 12,
		discountPrice: 54900,
		price: 99900,
		promotion: {
			timeToLiveValue: 6,
			period: '6 месяцев'
		},
		transcription: '',
		productId: 'kion_base_premium_p_04_01_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_07_2024',
		tariffPeriod: KION_TARIFF_PERIOD.MULTIMONTH6,
		image: '/images/20240427142200453ld8.d8a2d75d.jpg',
		isBigPicture: KION_PICTURE_TYPE.BIG
	  },
	  {
		name: "KION+PREMIUM",
		description: "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
		ageRating: 12,
		discountPrice: 99900,
		price: 169000,
		promotion: {
			timeToLiveValue: 12,
			period: '12 месяцев'
		},
		transcription: '',
		productId: 'kion_base_premium_p_04_01_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_07_2024',
		tariffPeriod: KION_TARIFF_PERIOD.ANNUALLY,
		image: '/images/20240427142200453ld8.d8a2d75d.jpg',
		isBigPicture: KION_PICTURE_TYPE.BIG
	  }
  ];

  private _selectedSubscription: Subscription = this.subscriptions[0]

  get grclientid(): string | null {
    return this._grclientid
  }

  set grclientid(value: string | null) {
    this._grclientid = value
  }

  get userId(): string | null {
    return this._userId;
  }

  set userId(value: string | null) {
    this._userId = value;
  }

  get userAvatar(): string | null {
    return this.webSSOJwt?.picture || null;
  }

  get webSSOJwt(): WebSsoJWT | {[key: string]: string} | null {
    return this._webSSOJwt;
  }

  set webSSOJwt(value: WebSsoJWT | {[key: string]: string} | null) {
    this._webSSOJwt = value;
  }

  private token: null | string = null
  private _jwtToken = ''
  private _webSSOJwt: null | WebSsoJWT | {[key: string]: string} = null
  private _promoCode: string | null = null
  private _previousPage = ''
  private _phone = ''
  private _userId: null | string = null
  private _grclientid: null | string = null
  private _clientTerminalId = ''
  public promo: Promo = {
    promoCode: '',
    productId: '',
    discountPrice: 0
  }

  private post<T>(
    endpoint: string,
    payload: { [key: string]: unknown } | string = {},
    externalOptions = {}
  ): Promise<AxiosResponse<T>> {
    let options: AxiosRequestConfig = {
      baseURL: '/api',
      ...externalOptions
      // withCredentials: true
    }

    if (this.token) {
      options = {
        ...options,
        headers: {
          'Authorization': `Bearer ${ this.token }`
        }
      }
    }

    return axios.post<T>(endpoint, payload, options)
  }

  public auth(nonce: string, webssoCode: string): Promise<AxiosResponse<AuthResponse>> {
    const clientTerminalId = this.clientTerminalId = this.phone + '-' + uuidv4()
    return this.post<AuthResponse>(
      '/ott/actions/websso-login',
      {
        clientTerminalId,
        terminalType: '2',
        deviceModel: 'Landing',
        deviceName: 'welcome_kion_ru',
        deviceOS: 'Landing KION',
        refreshTokenSupported: false,
        webssoCode,
        nonce
      }
    )
  }

  public getUserId() {
    const options: AxiosRequestConfig = {
      baseURL: '/coreapi',
      headers: {
        'Authorization': `Bearer ${ this.token }`
      }
    }
    return axios.get<{huaweiSubscriberId: string}>('/subscribers/me',  options)
  }

  public isMtsAccount() {
    const options: AxiosRequestConfig = {
      baseURL: '/privateapi',
      headers: {
        'Authorization': `Bearer ${ this.token }`
      }
    }
    return axios.get<{saleBlock: null | string}>('/account/me', options)
  }

  public checkSubscription(data: RelatedSubscriptionsTextRequest) {
    const options: AxiosRequestConfig = {
      baseURL: '/tvh-subscriptions',
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    }
	const payload = {
		productGid: data.productGid,
		promoCode: data.promoCode,
		paymentType: data.paymentType,
	}
    return this.post<CancelingProductGids>('/subscription/related-subscriptions-text', payload, options);
  };

  public payWithPhone({ productId, discountPrice }: Subscription) {
    const options: AxiosRequestConfig = {
      baseURL: '/subscriptionapi'
    }
    const payload = {
      productId,
      price: discountPrice,
      programId: null,
      promoCode: 'KIONMAIL1',
      clientTerminalId: this.clientTerminalId
    }
    return this.post('/subscriptions/v2', payload, options)
  }

  public async getWidgetConfig(subscription: Subscription, callback: (resp: MtsBankWidgetResponse) => void): Promise<MtsBankWidgetConfig> {
    // const { webssoSessionToken } = (await this.getPaymentToken()).data
    const { sessionId } = (await this.getPaymentSession(subscription)).data
    return new Promise<MtsBankWidgetConfig>(res => res({
      sessionId, //sessionId,
      scenarioType: 'pay',
      tokenId: this.jwtToken,
      environment: 'PROD',
      resultHandler: callback
    }))
  }

  private getPaymentSession({ productId, discountPrice }: Subscription) {
    const options: AxiosRequestConfig = {
      baseURL: '/cardapi'
    }

    const payload: { [key: string]: null | string | number | boolean } = {
      productId,
      price: discountPrice,
      programId: null,
      marketingType: this.promoCode?.toLowerCase() !== 'kionmir' ? 'LANDING' : 'MIR_CASHBACK',
      promocode: 'KIONMAIL1',
      support3ds: true
    }

    return this.post<{ sessionId: string }>('/payment/create-session', payload, options)
  }

  public getImgsForSwapper() {
    const config: AxiosRequestConfig = {
      headers: {
        'X-Device-Model': 'AndroidPhone_Widevine_VMX_v3',
        'X-Device-Id': 'premium_kion_ru',
        'X-App-Version': '1'
      }
    }

    return axios.get<KionPostersResponse>('/shelves/glo_shelf_cm_1699', config)
  }

  public getContentFromQueryParams(id: string, type: string) {
	  const config: AxiosRequestConfig = {
		  headers: {
			  'X-Request-Source': 'LANDING_HELLO_KION',
			  'X-Device-Id': 'premium_kion_ru',
			  'X-Device-Model': 'AndroidPhone_Widevine_VMX_v3',
			  'X-App-Version': '1'
		  },
		  baseURL: '/mgwapi',
	  }

	  return axios.get<KionContent>(`/api/content/${type}/${id}`, config)
  }

  public filterAppliedSubscriptions({ productId }: Subscription) {
    const options: AxiosRequestConfig = {
      baseURL: '/subscriptionapi'
    }

    return this.post<KionPromoCodeResponse[]>('/subscriptions/actions/get-subscriptions-by-filter', { productId }, options)
  }

  get phone() {
    return this._phone
  }

  set phone(phone: string) {
    this._phone = phone
  }

  get jwtToken() {
    return this._jwtToken
  }

  set jwtToken(str: string) {
    this._jwtToken = str
  }

  get clientTerminalId(): string {
    return this._clientTerminalId
  }

  set clientTerminalId(value: string) {
    this._clientTerminalId = value
  }

  public set promoCode(str: string | null) {
    if (!str) {
      this.promo.promoCode = null
      this._promoCode = null
    } else {
      this.promo.promoCode = str.toUpperCase()
      this._promoCode = str.toUpperCase()
    }
  }

  public get promoCode(): string | null {
    return this._promoCode
  }

  public set accessToken(str: string) {
    this.token = str
  }

  public getAnalyticsQuery() {
    let text: any = window.sessionStorage.getItem("analyticsQuery")
    if (text) {
        text = JSON.parse(text)
    } else {
        text = undefined
    }
    return text;
}

  // GTM

  public pushEvent(_dl: DataLayer = {}) {
    const data = {
        ...this._getCommonData(),
        ..._dl
    }

    if (process.env.NODE_ENV === 'production') {
        dataLayer.push(data)
        dataLayerMTS.push(data)
    } else {
        console.info('[GTM] Data pushed to dataLayer', data)
        console.info('[GTM] Data pushed to dataLayerMTS', data)
    }
}

public clearSessionSaveOldValues(
	requestId: string | null, 
	analyticsQuery: string | null, 
	content?: string | null, 
	subscriptionPeriod?: string | null
) {
    window.sessionStorage.clear()
    if (requestId) {
        window.sessionStorage.setItem("request_id", requestId)
    }
	if (analyticsQuery) {
		window.sessionStorage.setItem("analyticsQuery", analyticsQuery)
	}
	if (content) {
		window.sessionStorage.setItem("content", content)
	}
	if (subscriptionPeriod) {
		window.sessionStorage.setItem("subscription_period", subscriptionPeriod)
	}
}

_getCommonData(): DataLayer {
    return {
        event: 'screen_show',
        event_name: 'screen_show',
        userId: this.userId,
        screen: '/hello_landing',
        client_id: getClientID(),
        event_datetime: new Date().toISOString(),
        experiments: [],
        huawei_subscriber_id: this.grclientid || 'mts_guest',
        profile_id: this.grclientid || 'mts_guest',
        platform: 'web30_landing_hello',
        profile_age: null,
    }
  }
}